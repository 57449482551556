import { useSnackbar } from "notistack"
import { useQuery } from "@tanstack/react-query"

import useAxiosPrivate from "./useAxiosPrivate"

export type FetchEntitiesProps = {
    endPoint: string
    page?: number
    perPage?: number
    sortModel?: {
        field: any
        sort: string
    }[]
    filterModel?:
        | {
              column_field: string
              operator_value: any
              value: any
          }[]
        | null
    searchText?: string
    linkOperator?: string
}

// Fetch/Get All Entities List Per-Page, with Filter, Search and Sort Preferences Hook
const useFetchEntitiesPerPage = (props: FetchEntitiesProps, options?: any) => {

    const { endPoint, page, perPage, filterModel, sortModel, searchText } =
        props

    const { enqueueSnackbar } = useSnackbar()

    const axiosPrivate = useAxiosPrivate()

    return useQuery({
        queryKey: [
            endPoint,
            page,
            perPage,
            filterModel,
            sortModel,
            undefined,
            searchText,
        ],
        queryFn: () =>
            axiosPrivate
                .get(`/system/${endPoint}`, {
                    params: {
                        page: page,
                        per_page: perPage,
                        sort:
                            sortModel?.length === 0
                                ? undefined
                                : JSON.stringify(sortModel),
                        search: searchText,
                        filter:
                            filterModel?.length === 0
                                ? []
                                : JSON.stringify(filterModel),
                        link_operator: "and",
                    },
                })
                .then((response) => response.data),

        onError: (error: any) => {
            const fieldError =
                error.response?.data?.error?.field_error?.[0]?.description
            const errorMessage = error.response?.data?.error?.message
            enqueueSnackbar(fieldError || errorMessage || "Request Failed", {
                variant: "error",
            })
        },
        ...options,
    })
}

export default useFetchEntitiesPerPage
