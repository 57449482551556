import { useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Drawer,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { GridExpandMoreIcon } from "@mui/x-data-grid";

import useBreakPoints from "../../hooks/useBreakPoints";
import useUpdateEntity from "../../hooks/useUpdateEntity";
import useFetchEntities from "../../hooks/useFetchEntities";

interface UpdateRolesProps {
  update: boolean;
  roleData: any;
  handleUpdateModalClose: () => void;
  refetch: () => void;
}

interface UpdateRoleForm {
  name: string;
  permissions_id: string[];
}
// Edit/Update Roles Component
function UpdateRoles({
  handleUpdateModalClose,
  roleData,
  refetch,
  update,
}: UpdateRolesProps) {
  // USESTATE
  const [selectedPermissions, setSelecetdPermissions] = useState<any[]>([]);
  const [expanded, setExpanded] = useState<boolean>(false);
  const [selectedAll, setSelectedAll] = useState<boolean>(false);
  const permissionRef = useRef<any[]>([]);

  // HOOK FORM
  const { control, handleSubmit, register, setValue, watch } =
    useForm<UpdateRoleForm>({
      mode: "onChange",
    });

  const { sm } = useBreakPoints();

  // QUERIES $ MUTATIONS
  const { data: permissions, isLoading: permissionsIsLoading } =
    useFetchEntities({ endPoint: "/system/permissions" }) as any;
  const { data: role, isLoading: roleIsLoading } = useFetchEntities({
    endPoint: `system/roles/${roleData?.id}`,
  }) as any;
  const {
    mutate,
    isPending,
    data: roles,
  } = useUpdateEntity(`roles/${roleData?.id}`);

  // DATA STRUCTURE for Permissions Category By Name
  const permissionCategories = permissions?.data?.map(
    (element: { statement: { resource: string } }) =>
      element.statement.resource.split(":")[1]
  );
  const uniquePermissionCategories = Array.from(new Set(permissionCategories));

  permissionRef.current = watch("permissions_id");

  // HELPERS & EVENT HANDLERS
  const onSubmit = (formData: any) => {
    mutate(formData);
  };
  const handleSelectAll = (checked: boolean) => {
    const allPermissions = permissions?.data?.map(
      (item: { id: string }) => item.id
    );
    if (checked) setSelecetdPermissions(allPermissions);
    if (!checked) setSelecetdPermissions([]);
  };
  const handleSelect = (checked: boolean, category: string) => {
    const selectedPerm = permissions?.data?.filter(
      (element: { statement: { resource: string } }) =>
        element.statement.resource.split(":")[1] === category
    );
    const perm = selectedPerm?.map((item: { id: string }) => item.id);
    const unchecked = selectedPermissions?.filter(
      (value) => !perm.includes(value)
    );
    const permIntersect = Array.from(
      new Set([...selectedPermissions, ...perm])
    );
    if (checked) setSelecetdPermissions(permIntersect);
    if (!checked) setSelecetdPermissions(unchecked);
  };
  const handleItemSelect = (checked: boolean, permissionId: string) => {
    const unchecked = selectedPermissions?.filter(
      (value) => value !== permissionId
    );

    if (checked)
      setSelecetdPermissions(
        Array.from(new Set([...selectedPermissions, permissionId]))
      );
    if (!checked) setSelecetdPermissions(unchecked);
  };
  const viewSelectedCategory = (category: string) => {
    const categoryItems = permissions?.data
      ?.filter(
        (permission: { statement: { resource: string } }) =>
          permission?.statement?.resource.split(":")[1] === category
      )
      .map((item: { id: string }) => item.id);
    const selectedCategoryItems = permissions?.data?.filter(
      (permission: { id: any }) => selectedPermissions?.includes(permission.id)
    );
    const selected = selectedCategoryItems
      ?.filter(
        (permission: { statement: { resource: string } }) =>
          permission?.statement?.resource.split(":")[1] === category
      )
      .map((item: { id: string }) => item.id);

    return (
      selected?.length === categoryItems.length &&
      selected.every(
        (value: any, index: number) => value === categoryItems[index]
      )
    );
  };

  // USEFFECTS
  useEffect(() => {
    if (selectedPermissions?.length === permissions?.data?.length) {
      setSelectedAll(true);
    } else setSelectedAll(false);
  }, [permissions?.data?.length, selectedPermissions?.length]);

  useEffect(() => {
    setValue("name", role?.data?.name);
    setSelecetdPermissions(
      permissions?.data
        ?.filter((item: { name: string }) =>
          role?.data?.permissions?.includes(item.name)
        )
        ?.map((element: { id: any }) => element.id)
    );
  }, [setValue, role, permissions?.data]);

  useEffect(() => {
    setValue("permissions_id", selectedPermissions);
  }, [setValue, selectedPermissions]);

  useEffect(() => {
    if (roles) {
      handleUpdateModalClose();
      refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roles]);

  // RENDER
  return (
    <Drawer
      open={update}
      anchor="right"
      onClose={handleUpdateModalClose}
      PaperProps={{
        style: {
          width: sm ? "50%" : "100%",
        },
      }}
    >
      <DialogTitle
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          bgcolor: "#fafafa",
        }}
      >
        <Box>
          <Typography
            sx={{
              fontSize: 20,
              fontWeight: "bold",
              textAlign: "center",
            }}
          >
            Edit Role
          </Typography>
        </Box>
      </DialogTitle>
      <DialogContent>
        {permissionsIsLoading || roleIsLoading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              mt: 2,
              minHeight: "200px",
              minWidth: "250px",
            }}
          >
            <CircularProgress
              size={40}
              sx={{
                color: "black",
                marginTop: "-12px",
                marginLeft: "-12px",
              }}
            />
          </Box>
        ) : (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              mx: 2,
              mt: 2,
            }}
          >
            <Box>
              <Controller
                name="name"
                control={control}
                render={({ field }) => (
                  <TextField
                    variant="outlined"
                    label="Name"
                    autoComplete="name"
                    fullWidth
                    size="small"
                    disabled
                    sx={{
                      mb: 1,
                    }}
                    {...field}
                  />
                )}
              />
              <Divider />
            </Box>
            <Box
              sx={{
                overflowY: "auto",
                overflowX: "hidden",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Checkbox
                  checked={selectedAll}
                  onChange={(e) => handleSelectAll(e.target.checked)}
                  sx={{
                    "&.Mui-checked": {
                      color: "primary.main",
                    },
                  }}
                />
                <Typography
                  sx={{
                    fontSize: 18,
                    fontWeight: "bold",
                  }}
                >
                  Select All
                </Typography>
              </Box>
              <FormControl>
                {uniquePermissionCategories.map((category: any) => (
                  <FormGroup
                    key={category === undefined ? "Uncategorised" : category}
                    sx={{
                      mt: 1,
                    }}
                  >
                    <Accordion expanded={expanded === category}>
                      <AccordionSummary
                        expandIcon={
                          <IconButton
                            onClick={() => {
                              if (expanded === category) {
                                setExpanded(false);
                              } else {
                                setExpanded(category);
                              }
                            }}
                          >
                            <GridExpandMoreIcon
                              sx={{
                                color: "black",
                              }}
                            />
                          </IconButton>
                        }
                      >
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <Checkbox
                            checked={viewSelectedCategory(category)}
                            onChange={(e) =>
                              handleSelect(e.target.checked, category)
                            }
                            sx={{
                              "&.Mui-checked": {
                                color: "primary.main",
                              },
                            }}
                          />
                          <Typography
                            sx={{
                              fontSize: 18,
                              fontWeight: "bold",
                            }}
                          >
                            {category === undefined
                              ? "UNCATEGORISED"
                              : category.toUpperCase()}
                          </Typography>
                        </Box>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Box
                          sx={{
                            display: "inline-block",
                            ml: 4,
                          }}
                        >
                          {permissions?.data?.map(
                            (item: {
                              statement: {
                                resource: string;
                              };
                              id: string;
                              description: string;
                              name: string;
                            }) =>
                              item.statement.resource.split(":")[1] ===
                                category && (
                                <Box
                                  key={item.id}
                                  component="span"
                                  sx={{
                                    visibility: "visible",
                                    mt: 2,
                                    mx: 1,
                                    p: 0.7,
                                    bgcolor: "grey.100",
                                    color: "grey.800",
                                    border: "1px solid",
                                    borderColor: permissionRef.current
                                      ? permissionRef.current?.includes(
                                          item?.id
                                        )
                                        ? "primary.main"
                                        : "grey.300"
                                      : "gray.300",
                                    fontSize: 14,
                                    fontWeight: "700",
                                  }}
                                >
                                  <Tooltip title={item.description} arrow>
                                    <FormControlLabel
                                      key={item.id}
                                      value={item.id}
                                      control={
                                        <Checkbox
                                          key={item.id}
                                          checked={
                                            permissionRef.current
                                              ? permissionRef.current?.includes(
                                                  item.id
                                                )
                                              : false
                                          }
                                          onChange={(e) =>
                                            handleItemSelect(
                                              e.target.checked,
                                              item.id
                                            )
                                          }
                                          sx={{
                                            "&.Mui-checked": {
                                              color: "primary.main",
                                            },
                                          }}
                                        />
                                      }
                                      label={item.name}
                                      {...register("permissions_id")}
                                      name={item.id}
                                    />
                                  </Tooltip>
                                </Box>
                              )
                          )}
                        </Box>
                      </AccordionDetails>
                    </Accordion>
                  </FormGroup>
                ))}
              </FormControl>
            </Box>
          </Box>
        )}
      </DialogContent>
      <DialogActions
        sx={{
          mb: 1,
          pr: 4,
          pt: 2,
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          bgcolor: "#fafafa",
        }}
      >
        <Button
          size="small"
          variant="contained"
          sx={{
            ":hover": { bgcolor: "#e4e4e4", color: "primary.main" },
            backgroundColor: "#e4e4e4",
            textTransform: "none",
            fontWeight: "bold",
            width: 120,
            color: "primary.main",
          }}
          onClick={handleUpdateModalClose}
        >
          <Typography>Cancel</Typography>
        </Button>
        <Button
          size="small"
          variant="contained"
          sx={{
            bgcolor: "primary.main",
            ":hover": { bgcolor: "primary.main" },
            textTransform: "none",
            fontWeight: "bold",
            width: 120,
          }}
          type="submit"
          onClick={handleSubmit(onSubmit)}
          disabled={permissionsIsLoading || roleIsLoading || isPending}
        >
          <Typography sx={{ color: "secondary.main" }}>Update</Typography>
          {isPending && (
            <CircularProgress
              size={24}
              sx={{
                color: "#0a0a0a",
                position: "absolute",
                top: "50%",
                left: "50%",
                marginTop: "-12px",
                marginLeft: "-12px",
              }}
            />
          )}
        </Button>
      </DialogActions>
    </Drawer>
  );
}
export default UpdateRoles;
