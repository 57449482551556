import { SSO_BASE_URL, SSO_CLIENT_ID } from "../utils/config"
import useAuth from "../hooks/useAuth"
import { LoginRedirect } from "../utils/loginRedirect"

function IPIframe() {
    const { sessionState }: any = useAuth()
    let stat = "unchanged"
    const mes = `${SSO_CLIENT_ID} ${sessionState}`
    const targetOrigin = `${SSO_BASE_URL}` // Validates origin
    const opFrameId = "opIframe"
    let timerID: any = 0

    function checkSession() {
        const win =
            window.parent.frames[opFrameId as keyof typeof window.parent.frames]
                .contentWindow
        win.postMessage(mes, targetOrigin)
    }

    function setTimer() {
        checkSession()
        timerID = setInterval(checkSession, 5 * 1000)
    }

    // eslint-disable-next-line no-use-before-define
    window.addEventListener("message", receiveMessage, false)

    function receiveMessage(e: { origin: string; data: string }) {
        if (e.origin !== targetOrigin) {
            return
        }

        stat = e.data

        if (stat === "changed") {
            clearInterval(timerID)
            // ask for grant with prompt none
            LoginRedirect("none")
            // if success update refresh and access tokens
            // if failed remove refresh and access tokens
        } else if (stat === "error") {
            clearInterval(timerID)
        } else {
            console.log("no change")
        }
    }

    setTimer()

    return <div>You can not see me</div>
}

export default IPIframe
