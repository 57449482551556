import { createContext, useMemo, useState } from "react"

interface AuthProviderProps {
    children: any
}

const AuthContext = createContext({})

export function AuthProvider({ children }: AuthProviderProps) {
    const [auth, setAuth] = useState({})

    const [sessionState, setSessionState] = useState(
        localStorage.getItem("session_state") || ""
    )
    const [persist, setPersist] = useState(
        JSON.parse(localStorage.getItem("test") ?? "false")
    )
    const [permissions, setPermissions] = useState([])

    const value = useMemo(
        () => ({
            auth,
            setAuth,
            persist,
            setPersist,
            sessionState,
            setSessionState,
            permissions,
            setPermissions
        }),
        [auth, permissions, persist, sessionState]
    )
    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

export default AuthContext
