import { useEffect, useMemo, useRef, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  IconButton,
  MenuItem,
  Select,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  Add,
  Delete,
  HighlightOff,
  PlaylistRemove,
  TaskAlt,
} from "@mui/icons-material";

import useAccessControl from "../../hooks/useAccessControl";
import useBreakPoints from "../../hooks/useBreakPoints";
import useCreateEntity from "../../hooks/useCreateEntity";
import useUpdateEntity from "../../hooks/useUpdateEntity";
import useFetchEntitiesPerPage from "../../hooks/useFetchEntitiesPerPage";

type AssignOrRevokeRole = {
  open: boolean;
  handleEditModalClose: () => void;
  userData: any;
  refetch: () => void;
};
function AssignOrRevokeUserRole({
  open,
  handleEditModalClose,
  userData,
  refetch,
}: AssignOrRevokeRole) {
  const { systemUsersList, assignRole, revokeRole, rolesList } =
    useAccessControl() as any;
  const { sm } = useBreakPoints();
  const [roles, setRoles] = useState([]) as any;
  const selectedRole = useRef({});

  // MUTATION & QUERY
  const { data: rolesData, isLoading: roleIsLoading } = useFetchEntitiesPerPage(
    {
      endPoint: "roles",
      perPage: -1,
    },
    { enabled: rolesList }
  ) as any;
  const {
    isFetching,
    data: userWithRole,
    refetch: refetchUserData,
  } = useFetchEntitiesPerPage(
    {
      endPoint: "users",
      perPage: -1,
    },
    { enabled: systemUsersList }
  ) as any;
  const {
    mutate,
    isPending: assignIsLoading,
    isSuccess,
  } = useCreateEntity(`users/${userData?.sso_id}/roles`);

  const {
    mutate: revoke,
    isPending: revokeIsPending,
    isSuccess: revoked,
  } = useUpdateEntity(`users/${userData?.sso_id}/roles`);

  // HELPERS & EVENT HANDLERS
  const getUserRoles = useMemo(
    () =>
      userWithRole?.data
        ?.filter((user: { sso_id: string }) => user.sso_id === userData?.sso_id)
        ?.map((item: { roles: any }) => item.roles)[0]
        ?.map((role: { id: string; role_name: string; status: string }) => ({
          name: role?.role_name,
          id: role?.id,
          status: role?.status,
        })),
    [userData?.sso_id, userWithRole?.data]
  );
  const isSameRole = (a: { id: string }, b: { id: string }) => a.id === b.id;
  const onSubmit = (id: string) => {
    const roleToBeRemoved = getUserRoles?.filter(
      (previousValue: any) =>
        !roles?.some((currentValue: any) =>
          isSameRole(previousValue, currentValue)
        )
    );
    if (roleToBeRemoved?.length > 0) {
      revoke({ role_id: roleToBeRemoved[0]?.id });
    }
    if (id !== undefined) mutate({ role: id });
  };

  // USEEFFECT
  useEffect(() => {
    if (userWithRole) {
      setRoles(
        getUserRoles?.map(
          (role: { id: string; name: string; status: string }) => ({
            id: role?.id,
            name: role?.name,
            status: role?.status,
          })
        )
      );
    }
  }, [getUserRoles, userWithRole]);

  useEffect(() => {
    if (roles === undefined) {
      handleEditModalClose();
      refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roles]);
  useEffect(() => {
    if (isSuccess) {
      refetchUserData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);
  useEffect(() => {
    if (revoked) {
      setRoles(roles?.filter((item: any) => item !== selectedRole.current));
      refetchUserData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [revoked]);

  // RENDER
  return (
    <Dialog
      fullWidth
      scroll="paper"
      open={open}
      onClose={() => {
        refetch();
        handleEditModalClose();
      }}
    >
      <DialogTitle
        sx={{
          backgroundColor: "#FAFAFA",
          py: 3,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box>
          <Typography variant="h5" sx={{ fontWeight: "bold" }}>
            Update {`${userData?.first_name ?? "User"}'s`} Roles
          </Typography>
        </Box>
      </DialogTitle>
      <DialogContent
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          mt: 2,
          mx: 2,
        }}
      >
        {roleIsLoading || isFetching ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              mt: 2,
              minHeight: "200px",
              minWidth: "250px",
            }}
          >
            <CircularProgress
              size={40}
              sx={{
                color: "black",
                marginTop: "-12px",
                marginLeft: "-12px",
              }}
            />
          </Box>
        ) : (
          <>
            {roles?.map(
              (element: { id: string; name: string }, index: number) => (
                <Box
                  key={element?.id}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    width: "100%",
                    bgcolor: `${index % 2 !== 0 ? "#F5F5F5" : null}`,
                    my: 1,
                  }}
                >
                  <Box
                    key={element.id}
                    sx={{
                      display: "flex",
                      flexDirection: sm ? "row" : "column",
                      width: "100%",
                      bgcolor: "#fff",
                    }}
                  >
                    <FormControl
                      key={element.id}
                      margin="normal"
                      size="small"
                      fullWidth
                      sx={{
                        m: 0,
                      }}
                    >
                      <Select
                        key={element.id}
                        fullWidth
                        MenuProps={{
                          sx: {
                            "&& .Mui-selected": {
                              backgroundColor: "primary.main",
                            },
                            ml: 1,
                            my: 1,
                          },
                        }}
                        value={
                          rolesData?.data
                            ?.filter(
                              (item: { id: string }) => item?.id === element.id
                            )
                            ?.map((value: any) => value)[0]
                        }
                        onChange={(e: any) => {
                          setRoles(
                            roles?.map((item: any) => {
                              if (item?.id === element?.id) {
                                return {
                                  id: e.target.value.id,
                                  name: e.target.value.name,
                                  status: e.target.value.status,
                                };
                              }

                              return item;
                            })
                          );
                        }}
                      >
                        <MenuItem key={element?.id} value="">
                          <Tooltip
                            arrow
                            title="Revoke role"
                            placement="right-end"
                          >
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                m: ".1em",
                                backgroundColor: "whitesmoke",
                                color: "black",
                                borderRadius: "20px",
                                pr: "1em",
                                width: "95%",
                              }}
                            >
                              <IconButton size="small">
                                <PlaylistRemove />
                              </IconButton>
                              <Typography variant="subtitle2" noWrap>
                                None
                              </Typography>
                            </Box>
                          </Tooltip>
                        </MenuItem>
                        {rolesData?.data?.map((role: any) => (
                          <MenuItem
                            value={role}
                            key={role?.id}
                            sx={{
                              bgcolor: roles?.filter(
                                (item: any) => item.id === role.id
                              ).length
                                ? "primary.main"
                                : "",
                            }}
                            disabled={
                              roles?.filter((item: any) => item.id === role.id)
                                .length
                            }
                          >
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                m: ".1em",
                                backgroundColor:
                                  role?.status === "ACTIVE"
                                    ? "success.main"
                                    : "error.main",
                                color: "white",
                                borderRadius: "20px",
                                pr: "1em",
                                width: "95%",
                              }}
                            >
                              <IconButton
                                size="small"
                                sx={{
                                  color: "white",
                                }}
                              >
                                {role?.status === "ACTIVE" ? (
                                  <TaskAlt
                                    sx={{
                                      fontSize: "1em",
                                    }}
                                  />
                                ) : (
                                  <HighlightOff
                                    sx={{
                                      fontSize: "1em",
                                    }}
                                  />
                                )}
                              </IconButton>
                              <Typography variant="subtitle2" noWrap>
                                {role?.name}
                              </Typography>
                            </Box>
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        ml: sm ? 1 : 0,
                        mt: sm ? 0 : 1,
                      }}
                    >
                      <Button
                        sx={{
                          bgcolor: "#fdbc3d",
                          ":hover": {
                            bgcolor: "#fdbc3d",
                          },
                          textTransform: "none",
                          fontWeight: "bold",
                          color: "#fff",
                          width: 100,
                        }}
                        variant="contained"
                        type="submit"
                        onClick={() => {
                          onSubmit(element?.id);
                          selectedRole.current = element;
                        }}
                        disabled={
                          getUserRoles?.filter(
                            (item: any) => item.id === element.id
                          ).length ||
                          !assignRole ||
                          !revokeRole ||
                          assignIsLoading ||
                          revokeIsPending
                        }
                      >
                        Save
                      </Button>
                      <Button
                        size="small"
                        onClick={() => {
                          if (
                            element?.name === "" ||
                            element?.name === undefined
                          ) {
                            setRoles(
                              roles.filter((item: any) => item !== element)
                            );
                          } else {
                            revoke({
                              role_id: element?.id,
                            });
                            selectedRole.current = element;
                          }
                        }}
                        disabled={!revokeRole || revokeIsPending}
                      >
                        <Delete
                          sx={{
                            color: "red",
                          }}
                        />
                      </Button>
                    </Box>
                    <Divider hidden={sm} sx={{ my: "1em" }} />
                  </Box>
                </Box>
              )
            )}
            <Box
              sx={{
                boxShadow: 1,
                width: sm ? "30%" : "70%",
                bgcolor: "primary.main",
                borderRadius: "0.5em",
                px: "0.5em",
                fontSize: 12,
                display: "flex",
                my: 2,
              }}
            >
              <Button
                onClick={() => {
                  setRoles([
                    ...roles,
                    {
                      id: roles?.length,
                      name: "",
                      status: "",
                    },
                  ]);
                }}
                startIcon={<Add />}
                sx={{
                  color: "secondary.main",
                  width: "100%",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  textTransform: "none",
                }}
              >
                <Typography sx={{ textAlign: "center" }}>Add Role</Typography>
              </Button>
            </Box>
          </>
        )}
      </DialogContent>
      <DialogActions
        sx={{
          mb: 1,
          pr: 4,
          pt: 2,
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          bgcolor: "#fafafa",
        }}
      >
        <Button
          size="small"
          variant="contained"
          sx={{
            ":hover": { bgcolor: "#e4e4e4" },
            backgroundColor: "#e4e4e4",
            color: "primary.main",
            textTransform: "none",
            fontWeight: "bold",
            width: 120,
          }}
          onClick={() => {
            refetch();
            handleEditModalClose();
          }}
        >
          <Typography>Cancel</Typography>
        </Button>
      </DialogActions>
    </Dialog>
  );
}
export default AssignOrRevokeUserRole;
