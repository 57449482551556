import { useEffect } from "react";
import { useMutation } from "@tanstack/react-query";
import { useLocation, useNavigate } from "react-router-dom";
import { ADMIN_BASE_URL } from "../utils/config";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import useAuth from "../hooks/useAuth";
/// ?code=sldfjsdlkjf&session_state=sdfjsldfj
// ?error=sldkfjsdlfj&error_description=sldkfjdlfj

function Authorize() {
  const navigate = useNavigate();
  const location = useLocation();
  const { setAuth }: any = useAuth();

  const axiosPrivate = useAxiosPrivate();

  const params: any = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop: string) => searchParams.get(prop),
  });

  const { mutate } = useMutation({
    mutationFn: (code) =>
      axiosPrivate.post("/auth/token", {
        code: code,
        redirect_uri: `${ADMIN_BASE_URL}/authorize`,
        grant_type: "authorization_code",
      }),
  });

  useEffect(() => {
    if (params.session_state) {
      localStorage.setItem("session_state", params.session_state);
    }
    if (params.code) {
      mutate(params.code, {
        onSuccess: (data) => {
          localStorage.setItem(
            "refresh token",
            data?.data?.data?.refresh_token
          );
          setAuth(data?.data?.data);
          window.parent.postMessage("login-success", "*");
          JSON.parse(localStorage.getItem("registered") ?? "false")
            ? navigate("/")
            : navigate("/register");
        },
        onError: (error: any) => {
          console.error({
            error,
            msg: error?.response?.data?.error?.message,
            default: error?.message,
          });
        },
      });
    }
    if (params.error) {
      localStorage.removeItem("refresh token");
      window.parent.postMessage("login-fail", "*");
    }
  }, [
    location.pathname,
    mutate,
    navigate,
    params.code,
    params.error,
    params.sessionState,
    params.session_state,
    setAuth,
  ]);
  return null;
}
export default Authorize;
