import { useContext, useEffect } from "react"
import { Box } from "@mui/system"
import { CircularProgress } from "@mui/material"

import AuthContext from "../context/authProvider"
import { LoginRedirect } from "../utils/loginRedirect"

// Sign In with SSO
function SignIn() {
    // CONTEXT
    const { persist, setPersist }: any = useContext(AuthContext)

    // USEEFFECT
    useEffect(() => {
        localStorage.setItem("test", persist)
        LoginRedirect("email")
        setPersist(true)
    }, [persist, setPersist])

    // RENDER
    return (
        <Box
            sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                mt: 2,
                minHeight: "40vh",
            }}
        >
            <CircularProgress
                size={50}
                sx={{
                    color: "secondary.main",
                    marginTop: "-12px",
                    marginLeft: "-12px",
                }}
            />
        </Box>
    )
}
export default SignIn
