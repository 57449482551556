import { useMutation } from "@tanstack/react-query";
import useAxiosPrivate from "./useAxiosPrivate";
import { useSnackbar } from "notistack";

const useUpdateEntity = (path: string) => {
  const axiosPrivate = useAxiosPrivate();

  const { enqueueSnackbar } = useSnackbar();
  return useMutation({
    mutationFn: (formData: any) => {    
      return axiosPrivate.patch(`system/${path}`, formData );
    },
    onSuccess: () =>
      enqueueSnackbar(`Succesfull.`, {
        variant: "success",
        preventDuplicate: true,
        autoHideDuration: 2000,
      }),
    onError: (error: any) =>
      enqueueSnackbar(
        error.response?.data?.error?.field_error?.[0]?.description ||
          error?.response?.data?.error?.message ||
          error?.message ||
          "Network Error!",
        {
          variant: "error",
          preventDuplicate: true,
          autoHideDuration: 2000,
        }
      ),
  });
};

export default useUpdateEntity;
