import { useState } from "react"
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Button,
    Checkbox,
    CircularProgress,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Drawer,
    FormControl,
    FormControlLabel,
    FormGroup,
    TextField,
    Tooltip,
    Typography,
} from "@mui/material"
import { GridExpandMoreIcon } from "@mui/x-data-grid"

import useBreakPoints from "../../hooks/useBreakPoints"
import useFetchEntities from "../../hooks/useFetchEntities"

interface ViewRoleDetailsProps {
    view: boolean
    handleViewModalClose: () => void
    roleData: any
}
// View Roles Details Component
function ViewRoleDetails({
    handleViewModalClose,
    roleData,
    view,
}: ViewRoleDetailsProps) {

    // USESTATE
    const [expanded, setExpanded] = useState(false)

    const { sm } = useBreakPoints()

    // QUERIES $ MUTATIONS
    const { data: permissions, isLoading: permissionsIsLoading } = useFetchEntities({ endPoint: "/system/permissions" }) as any
    const { data: role, isLoading: roleIsLoading } = useFetchEntities({ endPoint: `/system/roles/${roleData?.id}` }) as any

    // DATA STRUCTURE for Permissions Category By Name
    const selectedPermissions = permissions?.data?.filter(
        (permission: { name: string }) =>
            role?.data?.permissions?.includes(permission.name)
    )
    const permissionCategories = selectedPermissions?.map(
        (element: { statement: { resource: string } }) =>
            element.statement.resource.split(":")[1]
    )
    const uniquePermissionCategories = Array.from(new Set(permissionCategories))

    // RENDER
    return (
        <Drawer
            open={view}
            anchor="right"
            onClose={handleViewModalClose}
            PaperProps={{
                style: {
                    width: sm ? "50%" : "100%",
                },
            }}
        >
            <DialogTitle
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    bgcolor: "#fafafa",
                }}
            >
                <Box>
                    <Typography
                        sx={{
                            fontSize: 20,
                            fontWeight: "bold",
                            textAlign: "center",
                        }}
                    >
                        Role Details
                    </Typography>
                </Box>
            </DialogTitle>
            <DialogContent>
                {permissionsIsLoading || roleIsLoading ? (
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            mt: 2,
                            minHeight: "200px",
                            minWidth: "250px",
                        }}
                    >
                        <CircularProgress
                            size={40}
                            sx={{
                                color: "black",
                                marginTop: "-12px",
                                marginLeft: "-12px",
                            }}
                        />
                    </Box>
                ) : (
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "flex-start",
                            mx: 2,
                            mt: 2,
                        }}
                    >
                        <Box>
                            <TextField
                                variant="outlined"
                                label="Name"
                                defaultValue={roleData?.name}
                                autoComplete="name"
                                fullWidth
                                size="small"
                                disabled
                                sx={{
                                    mb: 1,
                                }}
                            />
                            <Divider />
                        </Box>
                        <Box
                            sx={{
                                overflowY: "auto",
                                overflowX: "hidden",
                            }}
                        >
                            <FormControl>
                                {uniquePermissionCategories.map(
                                    (category: any) => (
                                        <FormGroup
                                            key={
                                                category === undefined
                                                    ? "Uncategorised"
                                                    : category
                                            }
                                            sx={{
                                                mt: 1,
                                            }}
                                        >
                                            <Accordion
                                                expanded={expanded === category}
                                                onClick={() => {
                                                    if (expanded === category) {
                                                        setExpanded(false)
                                                    } else {
                                                        setExpanded(category)
                                                    }
                                                }}
                                            >
                                                <AccordionSummary
                                                    expandIcon={
                                                        <GridExpandMoreIcon
                                                            sx={{
                                                                color: "black",
                                                            }}
                                                        />
                                                    }
                                                >
                                                    <Box
                                                        sx={{
                                                            display: "flex",
                                                            alignItems:
                                                                "center",
                                                        }}
                                                    >
                                                        <Typography
                                                            sx={{
                                                                fontSize: 18,
                                                                fontWeight:
                                                                    "bold",
                                                            }}
                                                        >
                                                            {category ===
                                                            undefined
                                                                ? "UNCATEGORISED"
                                                                : category.toUpperCase()}
                                                        </Typography>
                                                    </Box>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <Box
                                                        sx={{
                                                            display:
                                                                "inline-block",
                                                            ml: 4,
                                                        }}
                                                    >
                                                        {permissions?.data?.map(
                                                            (item: {
                                                                statement: {
                                                                    resource: string
                                                                }
                                                                id: string
                                                            }) =>
                                                                item.statement.resource.split(
                                                                    ":"
                                                                )[1] ===
                                                                    category &&
                                                                selectedPermissions
                                                                    ?.filter(
                                                                        (el: {
                                                                            id: string
                                                                        }) =>
                                                                            el.id ===
                                                                            item.id
                                                                    )
                                                                    ?.map(
                                                                        (checked: {
                                                                            id: string
                                                                            description: string
                                                                            name: string
                                                                        }) => (
                                                                            <Box
                                                                                key={
                                                                                    item.id
                                                                                }
                                                                                component="span"
                                                                                sx={{
                                                                                    visibility:
                                                                                        "visible",
                                                                                    mt: 2,
                                                                                    mx: 1,
                                                                                    p: 0.7,
                                                                                    bgcolor:
                                                                                        "grey.100",
                                                                                    color: "grey.800",
                                                                                    border: "1px solid",
                                                                                    borderColor:
                                                                                        "primary.main",
                                                                                    fontSize: 14,
                                                                                    fontWeight:
                                                                                        "700",
                                                                                }}
                                                                            >
                                                                                <Tooltip
                                                                                    title={
                                                                                        checked.description
                                                                                    }
                                                                                    arrow
                                                                                >
                                                                                    <FormControlLabel
                                                                                        key={
                                                                                            checked.id
                                                                                        }
                                                                                        value={
                                                                                            checked.id
                                                                                        }
                                                                                        control={
                                                                                            <Checkbox
                                                                                                key={
                                                                                                    checked.id
                                                                                                }
                                                                                                checked
                                                                                                sx={{
                                                                                                    "&.Mui-checked":
                                                                                                        {
                                                                                                            color: "primary.mainb",
                                                                                                        },
                                                                                                }}
                                                                                            />
                                                                                        }
                                                                                        label={
                                                                                            checked.name
                                                                                        }
                                                                                        name={`${checked.id}`}
                                                                                    />
                                                                                </Tooltip>
                                                                            </Box>
                                                                        )
                                                                    )
                                                        )}
                                                    </Box>
                                                </AccordionDetails>
                                            </Accordion>
                                        </FormGroup>
                                    )
                                )}
                            </FormControl>
                        </Box>
                    </Box>
                )}
            </DialogContent>
            <DialogActions
                sx={{
                    mb: 1,
                    pr: 4,
                    pt: 2,
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    bgcolor: "#fafafa",
                }}
            >
                <Button
                    size="small"
                    variant="contained"
                    sx={{
                        ":hover": { bgcolor: "#e4e4e4", color: "primary.main" },
                        backgroundColor: "#e4e4e4",
                        color: "primary.main",
                        textTransform: "none",
                        fontWeight: "bold",
                        width: 120,
                    }}
                    onClick={handleViewModalClose}
                >
                    <Typography>Back</Typography>
                </Button>
            </DialogActions>
        </Drawer>
    )
}
export default ViewRoleDetails
